<script lang="ts" setup>
defineProps<{
	showSidebar: boolean;
	isShowBadgeActiveRaces: boolean;
	amountPromotions: number;
	additionalClasses?: Record<string, boolean>;
}>();
const emit = defineEmits<{ (event: "closeSidebar"): void }>();

const { t } = useT();
const localPath = useLocalePath();
const { data: appInitData } = useAppInitData();
const { handleSupportButtonClick } = useSupportChat();
const { logoutUser } = useLogout();
const { isDesktop } = useDevice();
const isGuest = useIsGuest();
const { open, close, isOpen } = useFunrizeModals();
const { questPageInfo, questData, isFreeSpinQuest, currentTask, isAdventQuest } = useGameQuest();
const { prizeDropActive, handleShowPopup } = usePrizeDrop({ open });
const { isFlipTheCoinVisible, isFlipTheCoinEnabled, isLimitOffer } = useFlipTheCoin();
const { depositInfoData } = useFunrizeDepositStreakData();
const { durationExceeded, promoOffer } = useExitOffer(open, close, isOpen);
const prepareImgUrl = useImage();
const { dailyCashbackData } = useDailyPlayback();
const { isEventTheme } = useEventsCollector();

const isShowRedeem = computed(() => appInitData.value?.gameMode === "SweepStakes" && appInitData.value?.hasDeposits);
const isShowQuestMission = computed(
	() =>
		appInitData.value?.gameMode === "SweepStakes" &&
		questPageInfo.value?.quest &&
		!isAdventQuest.value &&
		!isFreeSpinQuest.value
);
const isShowPartnerItem = computed(() => !isGuest.value && appInitData.value?.isAffiliate);
const isSeasonEnabled = computed(() => !isGuest.value && appInitData.value?.season?.isEnabled);
const isShowAdventMission = computed(() => appInitData.value?.gameMode === "SweepStakes" && isAdventQuest.value);
const isShowFreeSpinQuest = computed(() => !isGuest.value && isFreeSpinQuest.value);
const isDepositStreak = computed(() => !isGuest.value && appInitData.value?.depositStreak?.isActive);

const eventDecor = `url(${prepareImgUrl("/nuxt-img/event-decor/bg-sidebar.png", {
	width: 232,
	height: 815,
	loading: "lazy"
})}) no-repeat center bottom / cover`;

const handleSupportClick = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "left_menu",
		button_name: "support"
	});
	handleSupportButtonClick();
	emit("closeSidebar");
};

const isShowFlipTheCoin = computed(
	() => !isGuest.value && isFlipTheCoinVisible.value && isFlipTheCoinEnabled.value && !isLimitOffer.value
);

const handleLogoutClick = async () => {
	await logoutUser();
	navigateTo(localPath("/"), { external: true });
};
const handleRedeemClick = () => {
	window?.$store?.gaCash?.deposit?.({
		location: "redeem",
		step: "payments_method"
	});
	window?.$cash?.$router?.push?.("/cash/redeem/");
};
const handleQuestClick = () => {
	open("LazyOModalQuestMain");
};
const handleOpenSeason = () => {
	open("LazyOModalSeasonTower");
};
const handleDepositStreakClick = () => {
	open("LazyOModalDepositStreak");
};
const handleFreeSpinClick = () => {
	if (questData.value?.questInfo?.isAvailable) {
		open("LazyOModalFreeSpin");
		return;
	}
	open("LazyOModalFreeSpinAlternative");
};

watch(isGuest, (val) => {
	if (!val) {
		handleShowPopup();
	}
});

onMounted(() => {
	handleShowPopup();
});
</script>

<template>
	<Transition name="fade">
		<AOverlay v-if="showSidebar" class="sidebar-bg" :modifiers="['fixed']" @click="$emit('closeSidebar')" />
	</Transition>
	<aside data-tid="menu-container" :class="['sidebar', { show: showSidebar }, additionalClasses]">
		<LazyOStatusSystemLevel v-if="!isGuest" location="sidebar" data-tid="header-status-system" />
		<LazyOSidebarDailyWheel data-tid="header-fortune-wheel" />
		<LazyODailyPlaybackMenuSidebar v-if="dailyCashbackData" @toggle-sidebar="$emit('closeSidebar')" />
		<LazyMFreeSpinQuestSidebarWidget v-if="isShowFreeSpinQuest" @close-sidebar="$emit('closeSidebar')" />
		<LazyOSidebarBingo v-if="appInitData?.bingo?.isActive" @toggle-sidebar="$emit('closeSidebar')" />
		<LazyOScratchCardsSidebar
			v-if="!isGuest && appInitData?.scratchCardLottery?.isActive"
			data-tid="header-scratch-cards"
		/>
		<LazyOAdventCalendarMenuSidebar v-if="!isGuest && isShowAdventMission" data-tid="header-advent-calendar" />
		<LazyOPrizeDropsSidebar v-if="!isGuest && prizeDropActive" data-tid="header-prize-drops" />
		<LazyOSidebarFlipTheCoin v-if="isShowFlipTheCoin" data-tid="header-flip-coin" />
		<LazyODepositStreakMenuSidebar v-if="isDepositStreak" data-tid="header-deposit-streak" />
		<LazyOSidebarTimerOffer v-if="!durationExceeded && promoOffer" />

		<div class="theme-wrapper">
			<div class="links-wrapper" data-tid="menu-links" @click="$emit('closeSidebar')">
				<div v-if="isSeasonEnabled" class="link" data-tid="header-funtastic-season" @click="handleOpenSeason">
					<NuxtIcon name="20/skull" />
					<AText :size="16">
						{{ t("season.title") }}
					</AText>
				</div>

				<div
					v-if="!isGuest && isShowQuestMission"
					class="link"
					data-tid="header-daily-mission"
					@click="handleQuestClick"
				>
					<NuxtIcon name="20/goal" filled />
					<AText variant="toledo">
						{{ t("Your Daily Mission") }}
					</AText>
					<ABadge v-if="currentTask">
						<AText variant="texas">1</AText>
					</ABadge>
				</div>
				<NuxtLink :to="localPath('/issues/all-games/')" class="link" data-tid="header-top-games">
					<NuxtIcon name="20/slots" filled />
					<AText variant="toledo">
						{{ t("Games") }}
					</AText>
				</NuxtLink>
				<div v-if="isDepositStreak" class="link" data-tid="header-prize-machine" @click="handleDepositStreakClick">
					<NuxtIcon name="20/prize-machine" filled />
					<AText variant="toledo">
						{{ t("Prize Machine") }}
					</AText>
					<ABadge v-if="depositInfoData?.canWheel">
						<AText variant="texas">1</AText>
					</ABadge>
				</div>
				<NuxtLink v-if="!isGuest" :to="localPath('/promotions/')" class="link" data-tid="header-promotions">
					<NuxtIcon name="20/bonus" filled />
					<AText variant="toledo"> {{ t("Promotions") }}</AText>
					<NuxtImg
						v-if="isEventTheme"
						src="/nuxt-img/event-decor/sale.webp"
						class="sale"
						width="132"
						height="72"
						alt="sale-img"
						loading="lazy"
					/>
					<ABadge v-else-if="amountPromotions > 0">
						<AText variant="texas">{{ amountPromotions }}</AText>
					</ABadge>
				</NuxtLink>
				<NuxtLink v-if="!isGuest" :to="localPath('/races/')" class="link" data-tid="header-races">
					<NuxtIcon name="20/race-next-active" filled />
					<AText variant="toledo">
						{{ t("Races") }}
					</AText>
					<ABadge v-if="isShowBadgeActiveRaces"> <AText variant="texas">1</AText> </ABadge>
				</NuxtLink>
				<div
					v-if="!isGuest && isFreeSpinQuest"
					class="link"
					data-tid="header-daily-mission"
					@click="handleFreeSpinClick"
				>
					<NuxtIcon name="20/free-spin-flag" filled />
					<AText variant="toledo">{{ t("freeSpin.sidebar.link.name") }}</AText>
				</div>
				<NuxtLink v-if="!isGuest && prizeDropActive" :to="localPath('/prize-drops/')" class="link">
					<NuxtIcon name="20/stars" filled />
					<AText variant="toledo">
						{{ t("Shooting Stars") }}
					</AText>
				</NuxtLink>
				<NuxtLink
					v-if="!isGuest && appInitData?.scratchCardLottery?.isActive"
					:to="localPath('/scratch-cards/')"
					class="link"
					data-tid="header-scratch-cards"
				>
					<NuxtIcon name="20/scratch-mania" filled />
					<AText variant="toledo">
						{{ t("Scratch mania") }}
					</AText>
				</NuxtLink>
				<NuxtLink v-if="!isGuest" :to="localPath('/game/')" class="link" data-tid="header-account">
					<NuxtIcon name="20/account" filled />
					<AText variant="toledo">
						{{ t("Account") }}
					</AText>
				</NuxtLink>
				<NuxtLink v-if="!isGuest" :to="localPath('/how-it-works/')" class="link" data-tid="header-how-it-works">
					<NuxtIcon name="20/info" filled />
					<AText variant="toledo">
						{{ t("How it works") }}
					</AText>
				</NuxtLink>
				<NuxtLink v-if="!isGuest" :to="localPath('/invite-friends/')" class="link" data-tid="header-invite-friends">
					<NuxtIcon name="20/invite-friends" filled />
					<AText variant="toledo">
						{{ t("Get") }}
					</AText>
					<AText variant="toledo" :modifiers="['semibold']" class="text-cixi earn">
						<NuxtIcon name="20/entries" class="invite" filled />
						2000
					</AText>
				</NuxtLink>
				<div v-if="!isGuest && isShowRedeem" class="link" data-tid="header-redeem" @click="handleRedeemClick">
					<NuxtIcon name="20/redeem" filled />
					<AText variant="toledo">
						{{ t("Redeem") }}
					</AText>
				</div>
				<template v-if="!isGuest">
					<NuxtLink
						data-tid="header-referral-info"
						:to="localPath('/referral-info/')"
						:class="['link', { 'is-disabled': appInitData?.isAffiliate }]"
					>
						<NuxtIcon name="20/afiliate" filled />
						<AText variant="toledo">
							{{ t("Affiliate") }}
						</AText>
					</NuxtLink>
					<NuxtLink
						v-if="isShowPartnerItem"
						:to="localPath('/affiliates/')"
						class="link sublink"
						data-tid="header-affiliates"
					>
						<NuxtIcon name="20/afiliate-account" filled />
						<AText variant="toledo">
							{{ t("Partner's account") }}
						</AText>
					</NuxtLink>
				</template>
			</div>
			<div>
				<ADivider />
				<div class="links-wrapper">
					<div class="link" data-tid="header-support" @click="handleSupportClick">
						<NuxtIcon name="20/support" filled />
						<AText variant="toledo">
							{{ t("Support") }}
						</AText>
					</div>
					<div v-if="!isGuest && !isDesktop" class="link" data-tid="header-logout" @click="handleLogoutClick">
						<NuxtIcon name="20/log-in" filled />
						<AText variant="toledo">
							{{ t("Logout") }}
						</AText>
					</div>
				</div>
			</div>
		</div>
	</aside>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition: all 250ms ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.overlay {
	z-index: 100;
	cursor: inherit;

	@include media-breakpoint-down(lg) {
		z-index: 2001;
	}
}

.sidebar-bg {
	width: 100%;
	display: block;
	background-color: var(--china);

	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.sidebar {
	background-color: var(--changping);
	position: fixed;
	width: 232px;
	left: 0;
	top: 64px;
	height: calc(100vh - 64px);
	flex-direction: column;
	gap: gutter(1);
	display: flex;
	padding: gutter(3.5) gutter(2);
	overflow: auto;
	transition: all 250ms ease-in-out;
	z-index: 100;

	@include media-breakpoint-up(lg) {
		&.has-rewards {
			top: 96px;
			height: calc(100vh - 96px);
		}

		&.has-email-notify {
			top: 118px;
			height: calc(100vh - 118px);
			&.has-duplicate-notice {
				top: 158px;
				height: calc(100vh - 158px);
			}
		}

		&.has-duplicate-notice {
			top: 104px;
			height: calc(100vh - 104px);
		}
	}

	@include media-breakpoint-down(lg) {
		top: 0;
		height: 100dvh;
		transform: translateX(-100%);
		z-index: 2001;

		&.show {
			transform: translateX(0);
		}
	}

	&::-webkit-scrollbar,
	&::-webkit-scrollbar-thumb {
		width: 0;
	}
}

.theme-wrapper {
	.event-theme & {
		position: relative;
		width: 100%;
		height: 100%;

		@include media-breakpoint-down(lg) {
			@media (orientation: landscape) {
				height: max-content;
			}
		}

		&::before {
			content: "";
			display: block;
			width: calc(100% + 32px);
			height: calc(100% + 56px);
			position: absolute;
			bottom: -28px;
			left: -16px;
			z-index: -1;
			background: v-bind(eventDecor);
		}
	}
}

.links-wrapper {
	display: flex;
	flex-direction: column;
}

.link {
	display: flex;
	align-items: center;
	gap: gutter(1.5);
	position: relative;
	z-index: 1;
	cursor: pointer;
	padding: gutter(1.5) 0;
	transition: 0.3s;
	color: var(--caringin);

	.earn {
		margin-left: -8px;
	}

	&:not(.season) {
		&:deep(svg) {
			g {
				opacity: 0.5;
			}
			&:not(:has(g)) {
				opacity: 0.5;
			}
		}
	}

	.invite {
		&:deep(svg) {
			g {
				opacity: 1;
			}
		}
	}

	.nuxt-icon {
		font-size: 20px;
		&:not(.invite) {
			&:deep(path:not([fill])) {
				stroke: var(--conakry);
			}
			&:deep(path[fill]) {
				fill: var(--conakry);
			}
		}
	}

	&:hover {
		opacity: 0.6;
	}

	&.router-link-active {
		color: var(--cannes);

		.nuxt-icon {
			font-size: 20px;

			:deep(svg) {
				opacity: 1;
				g {
					opacity: 1;
				}
			}

			&:not(.invite) {
				&:deep(path:not([fill])) {
					stroke: var(--cannes);
				}
				&:deep(path[fill]) {
					fill: var(--cannes);
				}
			}
		}
	}

	&.sublink {
		padding-left: gutter(2.5);
	}

	&.is-disabled {
		pointer-events: none;
	}
}

.sale {
	position: relative;
	right: 16px;
	width: 33px;
	height: 18px;
}
</style>
